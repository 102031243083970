import * as React from 'react';
import { Router } from '@reach/router';
import { RouterComponent } from '../../../components/RouterComponent/RouterComponent';
import { LegacyForm } from '../../../templates/LegacyForm/LegacyForm';
// import * as styles from './costing.module.scss'

export interface ICostingProps{
    
}

export default function Legacy(){
    return(
        <Router basepath="/secure/legacy" component={ ({children}) => <RouterComponent children={children} /> }>
            <LegacyForm path=":contentType/:formId" />
        </Router>
    )
}