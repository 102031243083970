import * as React from 'react';
import { useState, useEffect } from 'react';
import{ RouteComponentProps, useParams } from '@reach/router';

import * as styles from './LegacyForm.module.scss';
import * as globalStyles from '../../globals.module.scss';

import { SiteGlobalConfig } from '../../site.config';
import { useMsal } from '@azure/msal-react';
import { useGraphFetchClient } from '../../services/auth.service';


export interface ILegacyFormProps extends RouteComponentProps{
}

export const LegacyForm = (props: ILegacyFormProps) => {
    const { contentType, formId } = useParams();
    const { instance } = useMsal();
    const fetchClient = useGraphFetchClient(instance);
    const [formData, setFormData] = useState<any[]>([]);

    const { GRAPH_ROOT_URL, CMS_SHAREPOINT_SITE_ID, CMS_REQUESTS_LIST_ID, CMS_SHAREPOINT_SITE_URL, CMS_FORMS_LIST_ID, SP_TENANT_ROOT_URL, LEGACY_POLICY_COSTING_FORM_LIST_ID, LEGACY_ADVICE_FORM_LIST_ID, LEGACY_EXTRANET_SITE_ID } = SiteGlobalConfig;

    useEffect(() => {
        if(formId){
            getForm();
        }
    },[formId]);

    const getForm = async () => {
        const formUri = `${ GRAPH_ROOT_URL }/sites/${ LEGACY_EXTRANET_SITE_ID }/lists/${ contentType.match(/advice/gi) ? LEGACY_ADVICE_FORM_LIST_ID : LEGACY_POLICY_COSTING_FORM_LIST_ID }/items/${formId}`;

        const headers = { 
            "accept": "application/json", 
            "content-type": "application/json",
            "prefer": "HonorNonIndexedQueriesWarningMayFailRandomly"
        };

        const formRes = await fetchClient.fetch(formUri, { headers });

        if(formRes.ok){
            const json = await formRes.json();
            const { fields } = json;

            const keys = Object.keys(fields);
            const displayKeys = [
                "Title",
                "MP",
                "Party",
                "Description",
                "Notes",
                "Linked_x0020_to_x0020_request",
                "Linked_x0020_request_x0020_detai",
                "Related_x0020_to_x0020_entity",
                "Value_x0020_of_x0020_advice",
                "Advice_x0020_output",
                "Public_x0020_release",
                "Analysed_x0020_by_x0020_third_x0",
                "Distributional_x0020_Impact",
                "Funding_x0020_Index",
                "Intended_x0020_Implementation_x0",
                "Policy_x0020_Package",
                "Previous_x0020_Measure",
                "Public_x0020_Release",
                "Third_x0020_Party_x0020_Costing",
                "Third_x0020_Party_x0020_Funding",
                "Attachments"
            ];

            const data = displayKeys.map((k) => {
                const { [k]:value } = fields;

                return {
                    label: k.replace(/_x[^_]+_?/gi, " "),
                    key: k,
                    value: value && typeof(value) === "string" && value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/gi) ? (new Date(value)).toLocaleDateString('en-au') : value
                };
            });
            setFormData(data);
        }
    }
    
    return (
        <section className={ [globalStyles.sectionGrid, 'page-section-grid'].join(" ") }>
            <div className={ styles.formContainer }>
                {
                    formData && formData.length > 0  && formData.map((v) => {
                        const { label, value } = v;
                        return value !== null && value !== undefined ? (
                            <div>
                                <label>{ label }</label>
                                <p>{ typeof(value) === 'object' 
                                        ? value.Label 
                                        : ( 
                                            typeof(value) === "boolean" 
                                            ? (value === true ? "Yes" : "No")
                                            : value 
                                        )}</p>
                            </div>
                        ) : (<></>);
                    })
                }
            </div>
        </section>
    )
}